define("discourse/plugins/onlyoffice-discourse-footer/discourse/components/online-user", ["exports", "@ember/component", "@ember/service", "discourse-common/utils/decorators"], function (_exports, _component, _service, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    userService: (0, _service.inject)("user-service"),
    count() {}
  }, [["method", "count", [(0, _decorators.default)("userService.users")]]]));
});